import React, { useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useAllContext } from '../context/AllContext'

const ContactRequests = () => {

    const adminInfo = JSON.parse(localStorage.getItem('adminInfo'))

    const { getContactRequests, contactRequests } = useAllContext()

    useEffect(() => {
        getContactRequests(adminInfo.token)
    }, [])


    return (
        <div className='main-content'>
            <Header />

            <div className='padding'>
                <div className='container-fluid'>
                    <div className='row gx-3'>
                        <div className='col-lg-12col-12'>
                            <div className='card card1'>
                                <div className="card-header">
                                    <h5 className="mb-0 fs-18 fw-500 text-white">Contact Requests</h5>
                                </div>
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        <table className='table table1 mb-0' style={{ "minWidth": "650px" }}>
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ "width": "50px" }}>#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Telegram Account</th>
                                                    <th scope="col">Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    contactRequests && contactRequests.length > 0 ? contactRequests.map((items, ind) => {
                                                        const date = new Date(items.createdAt).toLocaleDateString()
                                                        return <tr key={ind}>
                                                            <td>{ind + 1}</td>
                                                            <td>{items.name}</td>
                                                            <td>{items.email}</td>
                                                            <td>{items.telegramAccount}</td>
                                                            <td>{date}</td>
                                                        </tr>
                                                    })
                                                        :
                                                        <tr>
                                                            <td colSpan={5}><p className='text-center mt-4'>No Records</p></td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default ContactRequests
