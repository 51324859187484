import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useAllContext } from '../context/AllContext';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

const UpdateBankAccount = () => {
    const adminInfo = JSON.parse(localStorage.getItem('adminInfo'))

    const params = useParams()
    const navigate = useNavigate()

    const { getBankAccounts, bankAccounts } = useAllContext()

    const [inputValue, setInputValue] = useState({
        bankName: '',
        bankAccountNumber: "",
        bankAccountName: ""
    });

    const [errors, setErrors] = useState({});

    const inputHandler = (e) => {
        const { name, value } = e.target;
        setInputValue({
            ...inputValue,
            [name]: value
        })
    }

    const validate = () => {
        let errors = {};
    
        if (!inputValue.bankName) {
          errors.bankName = 'Bank Name is required';
        }
    
        if (!inputValue.bankAccountNumber) {
            errors.bankAccountNumber = 'Bank Account Number is required';
        }
    
        if (!inputValue.bankAccountName) {
            errors.bankAccountName = 'Bank Account Name is required';
        }
    
        return errors;
    };

    const formSubmit = async (e) => {
        e.preventDefault()

        const formErrors = validate();
        setErrors(formErrors);

        const details = {
            id: params.bankId,
            bankName: inputValue.bankName,
            bankAccountNumber: inputValue.bankAccountNumber,
            bankAccountName: inputValue.bankAccountName
        }

        if (Object.keys(formErrors).length === 0) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/update-bank-account`, details, {
                    headers: {
                        Authorization: `${adminInfo.token}`
                    }
                })
                const data = await response.data

                if (data.status === 0) {
                    toast.error(data.message)
                }

                if (data.status === 1) {
                    toast.success(data.message)
                    getBankAccounts(adminInfo.token)
                    navigate('/create-bank-account')
                }

            } catch (error) {
                console.log('Error:', error.response.data.message);
                toast.error(error.response.data.message)
            }
        }
    }

    useEffect(() => {
        if (bankAccounts && bankAccounts.length > 0) {
            const bankData = bankAccounts.filter(banks => banks.id == params.bankId)
            
            setInputValue({
                bankName: bankData[0].bankName,
                bankAccountNumber: bankData[0].bankAccountNumber,
                bankAccountName: bankData[0].bankAccountName
            });
            
        }
    }, [bankAccounts]);

    useEffect(() => {
        getBankAccounts(adminInfo.token)
    }, [])
    

    return (
        <>
            <div className='main-content'>
                <Header />

                <div className='padding'>
                    <div className='container-fluid'>
                        <div className='row gx-3'>
                            <div className='col-lg-12 col-12'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-500 text-white">Update Bank Account</h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className='col-xl-7 col-lg-8 col-md-10 col-12'>
                                                <form className="form form1" onSubmit={formSubmit}>
                                                    <div className='mb-3'>
                                                        <label className='form-label text-white'>Bank Name</label>
                                                        <div className="icon-box">
                                                            <input type="text" className="form-control" placeholder="" value={inputValue.bankName} name="bankName" onChange={inputHandler} />
                                                            {errors.bankName && <p className="error-text">{errors.bankName}</p>}
                                                        </div>
                                                    </div>

                                                    <div className='mb-3'>
                                                        <label className='form-label text-white'>Bank Account Number</label>
                                                        <div className="icon-box">
                                                            <input type="text" className="form-control" placeholder="" value={inputValue.bankAccountNumber} name="bankAccountNumber" onChange={inputHandler} />
                                                            {errors.bankAccountNumber && <p className="error-text">{errors.bankAccountNumber}</p>}
                                                        </div>
                                                    </div>

                                                    <div className='mb-3'>
                                                        <label className='form-label text-white'>Bank Account Name</label>
                                                        <div className="icon-box">
                                                            <input type="text" className="form-control" placeholder="" value={inputValue.bankAccountName} name="bankAccountName" onChange={inputHandler} />
                                                            {errors.bankAccountName && <p className="error-text">{errors.bankAccountName}</p>}
                                                        </div>
                                                    </div>

                                                    <div className='mt-4'>
                                                        <button type='submit' className='btn btn1'>Update</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default UpdateBankAccount

