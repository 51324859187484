import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAllContext } from '../context/AllContext';

const MaintainanceMode = () => {

    const adminInfo = JSON.parse(localStorage.getItem('adminInfo'))
    
    const { getMaintenanceStatus, maintenanceStatus } = useAllContext()

    const [isChecked, setIsChecked] = useState(false);

    const handleToggle = (event) => {
        const newStatus = event.target.checked ? "on" : "off";
        setIsChecked(event.target.checked);
        submitMaintenanceStatus(newStatus)
    };
    

    const submitMaintenanceStatus = async (status) => {
        const sendData = {
            status: status
        }
        try {
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}/admin/set-maintenance-status`, sendData, {
                headers: {
                    Authorization: `${adminInfo.token}`
                }
            })
            const data = await resp.data

            if (data.status == 0) {
                toast.error(data.message)
            }

            if (data.status == 1) {
                toast.success(data.message)
                getMaintenanceStatus(adminInfo.token);
            }


        } catch (error) {
            console.log('Error:', error);
        }
        
    }
    
    useEffect(() => {
        if (maintenanceStatus) {
            const newStatus = maintenanceStatus.status === 'on' ? true : false;
            setIsChecked(newStatus);
        }
    }, [maintenanceStatus])

    useEffect(() => {
        getMaintenanceStatus(adminInfo.token);
    }, [])
    

    return (
        <>
            <div className='main-content'>
                <Header />

                <div className='padding'>
                    <div className='container-fluid'>
                        <div className='row gx-3'>
                            <div className='col-12'>
                                <div className='card card1'>
                                    <div className="card-body d-flex justify-content-between align-items-center">
                                        <div className=" me-3">
                                            <h5 className="mb-0 fs-18 fw-500 text-white">Maintainance Mode</h5>
                                        </div>
                                        <div className='form form1'>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" 
                                                id="formSwitchCheckDefault"
                                                checked={isChecked}
                                                onChange={handleToggle}
                                                style={{'width': '60px', 'height': '32px'}} />
                                                <label className="form-check-label" htmlFor="formSwitchCheckDefault"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* <div className='row gx-3'>
                            <div className='col-lg-6 col-12 mb-4'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-semibold">Exchange Rate</h5>
                                    </div>
                                    <div className="card-body">
                                        <p className="mb-0 text-center text-white-50">No Recoreds</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12 mb-4'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-semibold">Quick Transfer</h5>
                                    </div>
                                    <div className="card-body">
                                        <p className="mb-0 text-center text-white-50">No Recoreds</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default MaintainanceMode
