import React from 'react'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'

import './assets/css/style.css'
import './assets/css/responsive.css'

import "@fancyapps/ui/dist/fancybox/fancybox.css";

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import Dashboard from './views/Dashboard'

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify'
import PublicRoute from './auth/PublicRoute'
import PrivateRoute from './auth/PrivateRoute'
import Users from './views/Users'
import UserTransaction from './views/UserTransaction'
import Settings from './views/Settings'
import Signin from './views/Signin'
import ContactRequests from './views/ContactRequests'
import CreateBankAccount from './views/CreateBankAccount'
import CreateCryptoAccount from './views/CreateCryptoAccount'
import PaymentLink from './views/PaymentLink'
import Exchange from './views/Exchange'
import PaymentlinkTransactions from './views/PaymentlinkTransactions'
import UpdateBankAccount from './views/UpdateBankAccount'
import UserDeposit from './views/UserDeposit'
import UserWithdraw from './views/UserWithdraw'
import CheckMainAdmin from './auth/CheckMainAdmin'
import CheckSubAdmin from './auth/CheckSubAdmin'
import SetPin from './views/SetPin'
import Promptpay from './views/Promptpay'
import WithdrawRequest from './views/WithdrawRequest'
import Profit from './views/Profit'
import WidgetTransactions from './views/WidgetTransactions'
import MaintainanceMode from './views/MaintainanceMode'


function App() {
  return (
    <>
      <Router basename='/'>

        <Routes>

          <Route element={<PrivateRoute />} >
            <Route exect path="/" element={<Dashboard />} />
            <Route exect path="/users" element={<Users />} />
            <Route exect path="/user-transaction/:userId" element={<UserTransaction />} />
            <Route exect path="/user-deposit/:userId" element={<UserDeposit />} />
            <Route exect path="/user-withdraw/:userId" element={<UserWithdraw />} />
            <Route exect path="/settings" element={<Settings />} />
            <Route exect path="/profit" element={<Profit />} />
            <Route path="*" element={<Dashboard />} />
          </Route>

          <Route element={<CheckMainAdmin />} >
            <Route exect path="/contact-requests" element={<ContactRequests />} />
            <Route exect path="/create-bank-account" element={<CreateBankAccount />} />
            <Route exect path="/create-crypto-account" element={<CreateCryptoAccount />} />
            <Route exect path="/payment-link" element={<PaymentLink />} />
            <Route exect path="/exchange" element={<Exchange />} />
            <Route exect path="/paymentlink-transactions" element={<PaymentlinkTransactions />} />
            <Route exect path="/widget-transactions" element={<WidgetTransactions />} />
            <Route exect path="/update-bank-account/:bankId" element={<UpdateBankAccount />} />
            <Route exect path="/promptpay" element={<Promptpay />} />
            <Route exect path="/withdraw-request" element={<WithdrawRequest />} />
            <Route exect path="/maintainance-mode" element={<MaintainanceMode />} />
          </Route>

          <Route element={<CheckSubAdmin />} >
            <Route exect path="/set-pin" element={<SetPin />} />

          </Route>
          
          <Route element={<PublicRoute />} >
            <Route exect path="/signin" element={<Signin />} />
            <Route path="*" element={<Signin />} />
          </Route>

        </Routes>

      </Router>

      <ToastContainer position="bottom-center" autoClose={2000} />
    </>
  );
}

export default App;
