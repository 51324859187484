const PasswordValidation = (value) => {
    const errors = {}  
    if (!value.currentPassword) {
        errors.currentPassword = "Current Password is required"
    }

    if (!value.newPassword) {
        errors.newPassword = "New Password is required"
    }

    if (!value.confirmPassword) {
        errors.confirmPassword = "Confirm New Password is required"
    } else if (value.newPassword !== value.confirmPassword) {
        errors.confirmPassword = "New Password & Confirm New Password is not matched"
    }

    return errors;
}

export default PasswordValidation