import React, { useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { FaBtc, FaWallet } from 'react-icons/fa'
import { useAllContext } from '../context/AllContext'
import { SiTether } from 'react-icons/si'

const Dashboard = () => {

    const adminInfo = JSON.parse(localStorage.getItem('adminInfo'))

    const { getDashboardData, dashboardData } = useAllContext()

    useEffect(() => {
        getDashboardData(adminInfo.token);
    }, [])


    return (
        <>
            <div className='main-content'>
                <Header />

                <div className='padding'>
                    <div className='container-fluid'>
                        <div className='row gx-3'>
                            <div className='col-xl-4 col-md-4 col-sm-6 col-12 mb-4'>
                                <div className='card card1'>
                                    <div className="card-body">
                                        <div className="">
                                            <FaWallet className='mb-4 fs-1 text-main' />
                                        </div>
                                        <h3 className="mb-1 fw-semibold">{dashboardData.totalBalance ? dashboardData.totalBalance.toFixed(2).replace(/\.?0+$/, '') : '0'}</h3>
                                        <p className="mb-0 text-white-50">Total Balance THB</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-xl-4 col-md-4 col-sm-6 col-12 mb-4'>
                                <div className='card card1'>
                                    <div className="card-body">
                                        <div className="">
                                            <SiTether className='mb-4 fs-1 text-main' />
                                        </div>
                                        <h3 className="mb-1 fw-semibold">{dashboardData.totalBalanceUSDT ? dashboardData.totalBalanceUSDT.toFixed(2).replace(/\.?0+$/, '') : '0'}</h3>
                                        <p className="mb-0 text-white-50">Total Balance USDT</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-xl-4 col-md-4 col-sm-6 col-12 mb-4'>
                                <div className='card card1'>
                                    <div className="card-body">
                                        <div className="">
                                            <FaBtc className='mb-4 fs-1 text-main' />
                                        </div>
                                        <h3 className="mb-1 fw-semibold">{dashboardData.totalBalanceBTC ? dashboardData.totalBalanceBTC : '0'}</h3>
                                        <p className="mb-0 text-white-50">Total Balance BTC</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-xl-4 col-md-4 col-sm-6 col-12 mb-4'>
                                <div className='card card1'>
                                    <div className="card-body">
                                        <div className="">
                                            <FaWallet className='mb-4 fs-1 text-main' />
                                        </div>
                                        <h3 className="mb-1 fw-semibold">{dashboardData.totalDepositTHB ? dashboardData.totalDepositTHB.toFixed(2).replace(/\.?0+$/, '') : '0'}</h3>
                                        <p className="mb-0 text-white-50">Total Deposit THB</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-xl-4 col-md-4 col-sm-6 col-12 mb-4'>
                                <div className='card card1'>
                                    <div className="card-body">
                                        <div className="">
                                            <SiTether className='mb-4 fs-1 text-main' />
                                        </div>
                                        <h3 className="mb-1 fw-semibold">{dashboardData.totalDepositUSDT ? dashboardData.totalDepositUSDT.toFixed(2).replace(/\.?0+$/, '') : '0'}</h3>
                                        <p className="mb-0 text-white-50">Total Deposit USDT</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-xl-4 col-md-4 col-sm-6 col-12 mb-4'>
                                <div className='card card1'>
                                    <div className="card-body">
                                        <div className="">
                                            <FaBtc className='mb-4 fs-1 text-main' />
                                        </div>
                                        <h3 className="mb-1 fw-semibold">{dashboardData.totalDepositBTC ? dashboardData.totalDepositBTC : '0'}</h3>
                                        <p className="mb-0 text-white-50">Total Deposit BTC</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-xl-4 col-md-4 col-sm-6 col-12 mb-4'>
                                <div className='card card1'>
                                    <div className="card-body">
                                        <div className="">
                                            <FaWallet className='mb-4 fs-1 text-main' />
                                        </div>
                                        <h3 className="mb-1 fw-semibold">{dashboardData.totalWithdrawTHB ? dashboardData.totalWithdrawTHB.toFixed(2).replace(/\.?0+$/, '') : '0'}</h3>
                                        <p className="mb-0 text-white-50">Total Withdraw THB</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-xl-4 col-md-4 col-sm-6 col-12 mb-4'>
                                <div className='card card1'>
                                    <div className="card-body">
                                        <div className="">
                                            <SiTether className='mb-4 fs-1 text-main' />
                                        </div>
                                        <h3 className="mb-1 fw-semibold">{dashboardData.totalWithdrawUSDT ? dashboardData.totalWithdrawUSDT.toFixed(2).replace(/\.?0+$/, '') : '0'}</h3>
                                        <p className="mb-0 text-white-50">Total Withdraw USDT</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-xl-4 col-md-4 col-sm-6 col-12 mb-4'>
                                <div className='card card1'>
                                    <div className="card-body">
                                        <div className="">
                                            <FaBtc className='mb-4 fs-1 text-main' />
                                        </div>
                                        <h3 className="mb-1 fw-semibold">{dashboardData.totalWithdrawBTC ? dashboardData.totalWithdrawBTC : '0'}</h3>
                                        <p className="mb-0 text-white-50">Total Withdraw BTC</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className='row gx-3'>
                            <div className='col-lg-6 col-12 mb-4'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-semibold">Exchange Rate</h5>
                                    </div>
                                    <div className="card-body">
                                        <p className="mb-0 text-center text-white-50">No Recoreds</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 col-12 mb-4'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-semibold">Quick Transfer</h5>
                                    </div>
                                    <div className="card-body">
                                        <p className="mb-0 text-center text-white-50">No Recoreds</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default Dashboard
