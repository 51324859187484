import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const CheckPaymentlinkTransactions = ({pageCheck}) => {
    const adminInfo = JSON.parse(localStorage.getItem('adminInfo'))

    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false);

    const [completedTransaction, setCompletedTransaction] = useState(0);

    const audioRef = useRef(null);

    const getCompletedTransaction = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/completed-transaction`, {
                headers: {
                    Authorization: `${adminInfo.token}`
                }
            })
            const data = await response.data
            

            if(data.status === 1){
                setCompletedTransaction(data)
            }

            if(data.payment_links === "yes" || data.widget_transactions === "yes"){
                setShowModal(true)
            }

        } catch (error) {
            console.log('Error:', error.response.data.message);
        }
    }

    useEffect(() => {
        setInterval(() => {
            getCompletedTransaction()
        }, 15000)
    }, [])

    const handlePlay = () => {
        if (audioRef.current) {
            audioRef.current.play().catch(error => {
                console.error('Playback failed:', error);
            });
        }
    }

    useEffect(() => {
        if ((pageCheck !== "PaymentlinkTransactions" && pageCheck !== "WidgetTransactions") && showModal) {
            handlePlay()
        }
    }, [showModal])
    

    return (
        <>
            
            <button id="handleClickPlay" onClick={handlePlay} className='visually-hidden' style={{'position': 'absolute', 'zIndex': '-999'}}>Play Audio</button>
            <audio ref={audioRef}>
                <source src={require('../assets/audio/cash-register-kaching-sound.mp3')} type="audio/mp3" />
                Your browser does not support the audio element.
            </audio>
            
            {
                pageCheck !== "PaymentlinkTransactions" && pageCheck !== "WidgetTransactions" && <>
                    <div className={`modal modal1 fade ${showModal ? 'show d-block' : ''}`} id="paymentlinkModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                {/* <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div> */}
                                <div className="modal-body p-4 p-lg-5">
                                    <div className='text-center'>
                                        
                                        <h4>New cash in from a customer</h4>
                                        {
                                            completedTransaction?.payment_links === "yes" && <Link to={"/paymentlink-transactions"} className='btn btn1 mt-4'>Go to payment link transactions</Link>
                                        }
                                        {
                                            completedTransaction?.widget_transactions === "yes" && <Link to={"/widget-transactions"} className='btn btn1 mt-4'>Go to widget link transactions</Link>
                                        }
                                        
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        showModal && <div className="modal-backdrop fade show"></div>
                    }
                </>
            }
        </>
    )
}

export default CheckPaymentlinkTransactions
