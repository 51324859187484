import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import validation from "../validations/SigninValidation";


const Signin = () => {

    const navigate = useNavigate()

    const [inputValue, setInputValue] = useState({
        email: "",
        password: ""
    });

    const [errors, setErrors] = useState({});

    const inputHandler = (e) => {
        const {name, value} = e.target;
        setInputValue({
            ...inputValue,
            [name]: value
        })
    }
    

    const formSubmit = async (e) => {
        e.preventDefault()

        
        setErrors(validation(inputValue))
        
        if (inputValue.email !== "" && inputValue.password !== "") {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/login`, inputValue)
                const data = await response.data

                if(data.status === 0){
                    toast.error(data.message)
                }

                if(data.status === 1){
                    localStorage.setItem('adminInfo', JSON.stringify(data))
                    toast.success(data.message)
                    navigate('/')
                }

            } catch (error) {
                console.log('Error:', error.response.data.message);
                toast.error(error.response.data.message)
            }
        }
    }

    
    
    return (
        <>  
            <div className='login-section padding min-vh-100 d-flex flex-column justify-content-center'>
                <div className="container">
                    <div className="text-center mb-2">
                        <img src={require('../assets/images/logo.png')} alt="" className='img-fluid logo1 mb-4' />
                    </div>
                    <div className='login-box mx-auto'>
                        <h3 className="fw-semibold mb-4 text-white text-center">Login</h3>
                        <form action="" className="form form1" onSubmit={formSubmit}>
                            <div className="mb-3">
                                <input type="text" className="form-control" placeholder="Email" name="email" onChange={inputHandler} />
                                {errors.email && <p className="error-text">{errors.email}</p> }
                            </div>
                            <div className="mb-3">
                                <input type="password" className="form-control" placeholder="Password" name="password" onChange={inputHandler} />
                                {errors.password && <p className="error-text">{errors.password}</p> }
                            </div>

                            <div className='text-center mt-4'>
                                <button type='submit' className='btn btn1 mt-2'><span>Login</span></button>
                            </div>
                        </form>
                    </div>
                    <p className='text-center text-white mb-0 mt-3 fs-14'>Copyright © 2024 OnePay Global.</p>
                </div>
            </div>
            
        </>
    )
}

export default Signin