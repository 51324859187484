import React, { createContext, useContext, useReducer } from 'react'
import reducer from './AllReducer';
import axios from 'axios';

const AllContext = createContext()

const initialState = {
    users: [],
    userTransaction: [],
    contactRequests: [],
    dashboardData: [],
    bankAccounts: [],
    userBankAccounts: [],
    cryptoAccounts: [],
    paymentLinks: [],
    userCryptoAccounts: [],
    exchangeList: [],
    paymentLinkTransactions: [],
    userDeposits: [],
    userWithdraw: [],
    userDetails: [],
    promptpay: [],
    userPromptpay: [],
    userFees: [],
    withdrawRequest: [],
    widgetTransactions: [],
    maintenanceStatus: [],
    usersWidgetTransactions: []
}

const AllContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const getUsers = async (userToken) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/all-users`, {
                headers: {
                    Authorization: `${userToken}`
                }
            })
            const data = await response.data
            
            dispatch({ type: "setUsers", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }

    }

    const getUserTransaction = async (userToken, userId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/users-transactions`, {userId: userId}, {
                headers: {
                    Authorization: `${userToken}`
                }
            })
            const data = await response.data
            
            dispatch({ type: "setUserTransaction", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }

    }

    const getContactRequests = async (userToken) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/contact-requests`, {
                headers: {
                    Authorization: `${userToken}`
                }
            })
            const data = await response.data
            
            dispatch({ type: "setContactRequests", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }

    }

    const getDashboardData = async (userToken) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/dashboard`, {
                headers: {
                    Authorization: `${userToken}`
                }
            })
            const data = await response.data
            
            dispatch({ type: "setDashboardData", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }

    }

    const getBankAccounts = async (userToken) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/bank-accounts-list`, {
                headers: {
                    Authorization: `${userToken}`
                }
            })
            const data = await response.data
            
            dispatch({ type: "setBankAccounts", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }

    }

    const getUserBankAccounts = async (userToken, userId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/users-bank-accounts`, {userId: userId}, {
                headers: {
                    Authorization: `${userToken}`
                }
            })
            const data = await response.data
            
            dispatch({ type: "setUserBankAccounts", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }

    }

    const getCryptoAccounts = async (userToken) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/crypto-account-list`, {
                headers: {
                    Authorization: `${userToken}`
                }
            })
            const data = await response.data
            
            dispatch({ type: "setCryptoAccounts", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }

    }

    const getUserCryptoAccounts = async (userToken, userId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/users-crypto-accounts`, {userId: userId}, {
                headers: {
                    Authorization: `${userToken}`
                }
            })
            const data = await response.data
            
            dispatch({ type: "setUserCryptoAccounts", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }

    }

    const getPaymentLinks = async (userToken) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/payment-links`, {
                headers: {
                    Authorization: `${userToken}`
                }
            })
            const data = await response.data
            
            dispatch({ type: "setPaymentLinks", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }

    }

    const getExchangeList = async (userToken) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/p2p-exchange-list`, {
                headers: {
                    Authorization: `${userToken}`
                }
            })
            const data = await response.data
            
            dispatch({ type: "setExchangeList", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }

    }

    const getPaymentLinkTransactions = async (userToken) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/payment-link-transactions`, {
                headers: {
                    Authorization: `${userToken}`
                }
            })
            const data = await response.data
            
            dispatch({ type: "setPaymentLinkTransactions", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }

    }

    const getUserDeposits = async (userToken, userId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-users-deposits`, {userId: userId}, {
                headers: {
                    Authorization: `${userToken}`
                }
            })
            const data = await response.data
            
            dispatch({ type: "setUserDeposits", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }

    }

    const getUserWithdraw = async (userToken, userId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/get-users-withdraws`, {userId: userId}, {
                headers: {
                    Authorization: `${userToken}`
                }
            })
            const data = await response.data
            
            dispatch({ type: "setUserWithdraw", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }

    }

    const getUserDetails = async (userToken, userId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/user-details`, {userId: userId}, {
                headers: {
                    Authorization: `${userToken}`
                }
            })
            const data = await response.data
            
            dispatch({ type: "setUserDetails", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }

    }

    const getPromptpay = async (token) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/promptpay-list`, {
                headers: {
                    Authorization: `${token}`
                }
            })
            const data = await response.data
            
            dispatch({ type: "setPromptpay", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }

    }

    const getUserPromptpay = async (userToken, userId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/users-promptpays`, {userId: userId}, {
                headers: {
                    Authorization: `${userToken}`
                }
            })
            const data = await response.data
            
            dispatch({ type: "setUserPromptpay", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }

    }

    const getUserFees = async (userToken, userId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/users-fees`, {userId: userId}, {
                headers: {
                    Authorization: `${userToken}`
                }
            })
            const data = await response.data
            
            dispatch({ type: "setUserFees", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }

    }

    const getWithdrawRequest = async (userToken, userId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/all-withdraw-request`, {
                headers: {
                    Authorization: `${userToken}`
                }
            })
            const data = await response.data
            
            dispatch({ type: "setWithdrawRequest", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }

    }

    const getWidgetTransactions = async (token) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/widget-transactions`, {
                headers: {
                    Authorization: `${token}`
                }
            })
            const data = await response.data
            
            dispatch({ type: "setWidgetTransactions", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }

    }

    const getMaintenanceStatus = async (token) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/get-maintenance-status`, {
                headers: {
                    Authorization: `${token}`
                }
            })
            const data = await response.data
            
            dispatch({ type: "setMaintenanceStatus", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }

    }

    const getUsersWidgetTransactions = async (userToken, userId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/users-widget-transactions`, {userId: userId}, {
                headers: {
                    Authorization: `${userToken}`
                }
            })
            const data = await response.data
            
            dispatch({ type: "setUsersWidgetTransactions", payload: data })
        } catch (error) {
            console.log("Error:", error);
        }

    }

    
    return <AllContext.Provider value={{...state, 
        getUsers,
        getUserTransaction,
        getContactRequests,
        getDashboardData,
        getBankAccounts,
        getUserBankAccounts,
        getCryptoAccounts,
        getPaymentLinks,
        getUserCryptoAccounts,
        getExchangeList,
        getPaymentLinkTransactions,
        getUserDeposits,
        getUserWithdraw,
        getUserDetails,
        getPromptpay,
        getUserPromptpay,
        getUserFees,
        getWithdrawRequest,
        getWidgetTransactions,
        getMaintenanceStatus,
        getUsersWidgetTransactions
    }}>
        {children}
    </AllContext.Provider>
}

const useAllContext = () => {
    return useContext(AllContext)
}

export { AllContextProvider, useAllContext }