import React, { useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs'
import { toast } from 'react-toastify'
import PasswordValidation from '../validations/PasswordValidation'
import axios from 'axios'

const Settings = () => {

    const adminInfo = JSON.parse(localStorage.getItem('adminInfo'))

    const [showPasswords, setShowPasswords] = useState([false, false]);
    const [errors, setErrors] = useState({});

    const [inputValue, setInputValue] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    })

    const handleTogglePasswordVisibility = (index) => {
        const newShowPasswords = [...showPasswords];
        newShowPasswords[index] = !newShowPasswords[index];
        setShowPasswords(newShowPasswords);
    };


    const inputHandler = (e) => {
        const { name, value } = e.target;
        setInputValue({
            ...inputValue,
            [name]: value
        })
    }


    const formSubmit = async (e) => {
        e.preventDefault();

        setErrors(PasswordValidation(inputValue));

        const passwordData = {
            currentPassword: inputValue.currentPassword,
            newPassword: inputValue.newPassword,
            confirmPassword: inputValue.confirmPassword
        }

        if (inputValue.currentPassword &&
            inputValue.newPassword &&
            inputValue.confirmPassword &&
            inputValue.newPassword == inputValue.confirmPassword) {
            try {
                const resp = await axios.post(`${process.env.REACT_APP_API_URL}/admin/change-password`, passwordData, {
                    headers: {
                        Authorization: `${adminInfo.token}`
                    }
                })
                const data = await resp.data

                if (data.status == 0) {
                    toast.error(data.message)
                }

                if (data.status == 1) {
                    toast.success(data.message)
                    setInputValue({
                        currentPassword: '',
                        newPassword: '',
                        confirmPassword: ''
                    })
                }


            } catch (error) {
                console.log('Error:', error);
            }
        }

    }

    return (
        <>
            <div className='main-content'>
                <Header />

                <div className='padding'>
                    <div className='container-fluid'>
                        <div className='row gx-3'>
                            <div className='col-lg-12 col-12'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-500 text-white">Change Password</h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className='col-xl-7 col-lg-8 col-md-10 col-12'>
                                                <form action="" className="form form1" onSubmit={formSubmit}>
                                                    <div className='mb-3'>
                                                        <label className='form-label text-white'>Current Password</label>
                                                        <div className="icon-box">
                                                            <input type={showPasswords[1] ? 'text' : 'password'} id="currentPass" className="form-control" placeholder="" value={inputValue.currentPassword} name="currentPassword" onChange={inputHandler} />
                                                            {errors.currentPassword && <p className="error-text">{errors.currentPassword}</p>}
                                                            <a className="icon" id="toggleCurrentPass" onClick={() => handleTogglePasswordVisibility(1)}>
                                                                {
                                                                    showPasswords[1] ? <BsFillEyeSlashFill /> : <BsFillEyeFill />
                                                                }
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className='form-label text-white'>New Password</label>
                                                        <div className="icon-box">
                                                            <input type={showPasswords[2] ? 'text' : 'password'} id="newPass" className="form-control" placeholder="" value={inputValue.newPassword} name="newPassword" onChange={inputHandler} />
                                                            {errors.newPassword && <p className="error-text">{errors.newPassword}</p>}
                                                            <a className="icon" id="toggleNewPass" onClick={() => handleTogglePasswordVisibility(2)}>
                                                                {
                                                                    showPasswords[2] ? <BsFillEyeSlashFill /> : <BsFillEyeFill />
                                                                }
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className='form-label text-white'>Confirm New Password</label>
                                                        <div className="icon-box">
                                                            <input type={showPasswords[3] ? 'text' : 'password'} id="confirmPass" className="form-control" placeholder="" value={inputValue.confirmPassword} name="confirmPassword" onChange={inputHandler} />
                                                            {errors.confirmPassword && <p className="error-text">{errors.confirmPassword}</p>}
                                                            <a className="icon" id="toggleConfirmPass" onClick={() => handleTogglePasswordVisibility(3)}>
                                                                {
                                                                    showPasswords[3] ? <BsFillEyeSlashFill /> : <BsFillEyeFill />
                                                                }
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className='mt-4'>
                                                        <button type='submit' className='btn btn1'>Update</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default Settings
