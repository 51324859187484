import React, { useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs'
import { toast } from 'react-toastify'
import PasswordValidation from '../validations/PasswordValidation'
import axios from 'axios'
import PinInput from 'react-pin-input'

const SetPin = () => {

    const adminInfo = JSON.parse(localStorage.getItem('adminInfo'))
    
    const [errors, setErrors] = useState('');

    const [pin, setPin] = useState('');

    const handleChange = (value) => {
        setPin(value);
    };


    const formSubmit = async (e) => {
        e.preventDefault();

        const apiData = {
            pin: pin,
        }
        

        if (pin.length < 3) {
            setErrors("Pin is required")
        } else {
            setErrors("")
        }
        
        if (pin.length > 2) {
            try {
                const resp = await axios.post(`${process.env.REACT_APP_API_URL}/admin/update-pin`, apiData, {
                    headers: {
                        Authorization: `${adminInfo.token}`
                    }
                })
                const data = await resp.data

                if (data.status == 0) {
                    toast.error(data.message)
                }

                if (data.status == 1) {
                    toast.success(data.message)
                    // setPin('');
                }


            } catch (error) {
                console.log('Error:', error);
            }
        }

    }

    return (
        <>
            <div className='main-content'>
                <Header />

                <div className='padding'>
                    <div className='container-fluid'>
                        <div className='row gx-3'>
                            <div className='col-lg-12 col-12'>
                                <div className='card card1'>
                                    <div className="card-header text-center">
                                        <h5 className="mb-0 fs-18 fw-500 text-white">Set Pin</h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className='col-xl-12 col-lg-12 col-md-12 col-12'>
                                                <form action="" className="form form1" onSubmit={formSubmit}>
                                                    <div className='mb-3 d-flex justify-content-center'>
                                                        {/* <label className='form-label text-white'>Current Password</label> */}
                                                        <PinInput
                                                            length={3}
                                                            value={pin}
                                                            onChange={handleChange}
                                                            type="numeric"
                                                            inputMode="number"
                                                            // secret
                                                            // secretDelay={100}
                                                            // autoSelect={true}
                                                            className="form-control"
                                                            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                                                        />
                                                    </div>
                                                    {errors && <p className="error-text text-center">{errors}</p>}
                                                    <div className='mt-4 text-center'>
                                                        <button type='submit' className='btn btn1'>Set Pin</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default SetPin
