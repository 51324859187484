
const reducer = (state, action) => {
    switch (action.type) {
        case "setUsers":
            return {
                ...state,
                users: action.payload.data
            }

        case "setUserTransaction":
            return {
                ...state,
                userTransaction: action.payload.data
            }

        case "setContactRequests":
            return {
                ...state,
                contactRequests: action.payload.data
            }

        case "setDashboardData":
            return {
                ...state,
                dashboardData: action.payload
            }

        case "setBankAccounts":
            return {
                ...state,
                bankAccounts: action.payload.data
            }

        case "setUserBankAccounts":
            return {
                ...state,
                userBankAccounts: action.payload.data
            }

        case "setCryptoAccounts":
            return {
                ...state,
                cryptoAccounts: action.payload.data
            }

        case "setPaymentLinks":
            return {
                ...state,
                paymentLinks: action.payload.data
            }

        case "setUserCryptoAccounts":
            return {
                ...state,
                userCryptoAccounts: action.payload.data
            }

        case "setExchangeList":
            return {
                ...state,
                exchangeList: action.payload.data
            }

        case "setPaymentLinkTransactions":
            return {
                ...state,
                paymentLinkTransactions: action.payload.data
            }

        case "setUserDeposits":
            return {
                ...state,
                userDeposits: action.payload.data
            }

        case "setUserWithdraw":
            return {
                ...state,
                userWithdraw: action.payload.data
            }

        case "setUserDetails":
            return {
                ...state,
                userDetails: action.payload.record
            }

        case "setPromptpay":
            return {
                ...state,
                promptpay: action.payload.data
            }

        case "setUserPromptpay":
            return {
                ...state,
                userPromptpay: action.payload.data
            }

        case "setUserFees":
            return {
                ...state,
                userFees: action.payload.data
            }

        case "setWithdrawRequest":
            return {
                ...state,
                withdrawRequest: action.payload.data
            }

        case "setProfitCurrency":
            return {
                ...state,
                profitCurrency: action.payload
            }

        case "setWidgetTransactions":
            return {
                ...state,
                widgetTransactions: action.payload.data
            }

        case "setMaintenanceStatus":
            return {
                ...state,
                maintenanceStatus: action.payload
            }

        case "setUsersWidgetTransactions":
            return {
                ...state,
                usersWidgetTransactions: action.payload.data
            }
          
            
    
        default:
            return state;
    }
    
}

export default reducer