import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'

import { FaBars, FaCreditCard, FaExchangeAlt, FaMoneyBill, FaMoneyBillWave, FaUser } from 'react-icons/fa'
import { BsGridFill } from 'react-icons/bs'
import { FaBuildingColumns, FaGear } from 'react-icons/fa6'
import { BiSolidContact } from 'react-icons/bi'
import { GiTwoCoins } from 'react-icons/gi'
import CheckPaymentlinkTransactions from './CheckPaymentlinkTransactions'
import { CgPaypal } from 'react-icons/cg'
import { PiHandWithdrawBold } from 'react-icons/pi'
import { RiHandCoinFill } from 'react-icons/ri'
import { RxSwitch } from 'react-icons/rx'

const Header = ({pageName}) => {
    const adminInfo = JSON.parse(localStorage.getItem('adminInfo'))

    const navigate = useNavigate()

    const Logout = () => {
        localStorage.removeItem('adminInfo')
        localStorage.removeItem('cartData')
        navigate('/signin')
    }

    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    const date = new Date().toLocaleDateString()
    const dayNum = new Date().getDay()
    const day = weekday[dayNum];
    

    return (
        <>
            <CheckPaymentlinkTransactions pageCheck={pageName} />
            
            <div className='header-top'>
                <div className='container-fluid'>
                    <div className="row align-items-center">
                        <div className='col-6'>
                            <div className='d-none d-lg-block'>
                                <h5 className="fs-18 mb-1 text-white">Hey, Admin <span className='small text-main'>({adminInfo.email})</span></h5>
                                <p className="fs-14 mb-0 text-white-50">{day}, {date}</p>
                            </div>
                            <div className='d-block d-lg-none'>
                                <Link to={'/'} className='link'><img src={require('../assets/images/logo.png')} alt="" className='img-fluid logo1'/></Link>
                            </div>
                        </div>
                        <div className='col-6 d-flex justify-content-end align-items-center'>

                            {/* <div className='me-3'>
                                <Link to="/signin" className='link'><FaPowerOff /></Link>
                            </div> */}

                            {/* <div className="dropdown dropdown1 me-3">
                                <button className="btn btn-primary links1" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    <FaRegBell />
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <li><Link className="dropdown-item" to="#">Notification 1</Link></li>
                                    <li><Link className="dropdown-item" to="#">Notification 2</Link></li>
                                    <li><Link className="dropdown-item" to="#">Notification 3</Link></li>
                                    <li><Link className="dropdown-item" to="#">Notification 4</Link></li>
                                </ul>
                            </div> */}

                            <div className="dropdown dropdown1">
                                <button className="btn btn-primary" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={require('../assets/images/user.jpg')} className="img-fluid user1" alt="" />
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <li><Link className="dropdown-item" to="#">Profile</Link></li>
                                    <li><Link className="dropdown-item" to="/settings">Setting</Link></li>
                                    {
                                        adminInfo?.type === "sub" && <li><Link className="dropdown-item" to="/set-pin">Set Pin</Link></li>
                                    }
                                    <li><Link className="dropdown-item" to="/signin" onClick={Logout}>Logout</Link></li>
                                </ul>
                            </div>

                            <button className="btn ms-3 d-block d-lg-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling"><FaBars /></button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="offcanvas1 offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
                <div className="offcanvas-header">
                    <div className='d-block d-lg-none'>
                        <h5 className="fs-14 mb-1 text-white">Hey, Admin <span className='small text-main'>({adminInfo.email})</span></h5>
                        <p className="fs-12 mb-0 text-white-50">Monday, 22 July 2024</p>
                    </div>
                    <div className='d-none d-lg-block'>
                        <Link to={'/'} className='link'><img src={require('../assets/images/logo.png')} alt="" className='img-fluid logo1'/></Link>
                    </div>
                    <button type="button" className="btn-close d-block d-lg-none btn-close-white opacity-100" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body scrollbar">
                    <ul className="navbar-nav navbar-nav1 me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink className="nav-link" to='/'><BsGridFill /> Dashboard</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/users"><FaUser /> All Users</NavLink>
                        </li>
                        {
                            adminInfo?.type === "main" && <>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/create-crypto-account"><GiTwoCoins /> Create Crypto Account</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/create-bank-account"><FaBuildingColumns /> Create Bank Account</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/promptpay"><CgPaypal /> Create Promptpay Account</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/withdraw-request"><PiHandWithdrawBold /> Withdraw Request</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/contact-requests"><BiSolidContact /> Contact Requests</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/payment-link"><FaMoneyBillWave /> Payment Link</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/exchange"><FaExchangeAlt /> P2P Exchange</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/paymentlink-transactions"><FaMoneyBill /> Paymentlink Transactions</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/widget-transactions"><FaMoneyBill /> Widget Transactions</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/maintainance-mode"><RxSwitch /> Maintainance Mode</NavLink>
                                </li>
                            </> 
                        }
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/profit"><RiHandCoinFill /> Profit</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/settings"><FaGear /> Settings</NavLink>
                        </li>
                        {/* <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <FaBasketballBall /> Games
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <li><NavLink className="dropdown-item" to='/live-games'><FaArrowRight className="sports-arrow" /> Live Games</NavLink></li>
                                <li><NavLink className="dropdown-item" to='/past-games'><FaArrowRight className="sports-arrow" /> Past Games</NavLink></li>
                            </ul>
                        </li> */}
                    </ul>
                </div>
            </div>

        </>
    )
}

export default Header
