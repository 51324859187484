import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useAllContext } from '../context/AllContext'
import { useParams } from 'react-router-dom'
import ReactPaginate from 'react-paginate'

const UserDeposit = () => {

    const adminInfo = JSON.parse(localStorage.getItem('adminInfo'))

    const { getUserDeposits, userDeposits,
            getUsersWidgetTransactions, usersWidgetTransactions } = useAllContext()

    const params = useParams()
    
    
    // Pagination 1 (for currentItems)
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    // Pagination 2 (for currentItems2)
    const [currentItems2, setCurrentItems2] = useState([]);
    const [pageCount2, setPageCount2] = useState(0);
    const [itemOffset2, setItemOffset2] = useState(0);
    const [currentPage2, setCurrentPage2] = useState(0);

    const itemsPerPage = 8;

    // Effect for currentItems (first pagination)
    useEffect(() => {
        if (userDeposits) {
            // Calculate the total number of pages for currentItems
            const endOffset = itemOffset + itemsPerPage;
            setCurrentItems(userDeposits.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(userDeposits.length / itemsPerPage));
        }
    }, [itemOffset, itemsPerPage, userDeposits]);

    // Effect for currentItems2 (second pagination)
    useEffect(() => {
        if (usersWidgetTransactions) { // Assuming you have another array called usersWidgetTransactions
            // Calculate the total number of pages for currentItems2
            const endOffset2 = itemOffset2 + itemsPerPage;
            setCurrentItems2(usersWidgetTransactions.slice(itemOffset2, endOffset2));
            setPageCount2(Math.ceil(usersWidgetTransactions.length / itemsPerPage));
        }
    }, [itemOffset2, itemsPerPage, usersWidgetTransactions]);

    // Handle page click for currentItems (first pagination)
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % userDeposits.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected);
    };

    // Handle page click for currentItems2 (second pagination)
    const handlePageClick2 = (event) => {
        const newOffset2 = (event.selected * itemsPerPage) % usersWidgetTransactions.length;
        setItemOffset2(newOffset2);
        setCurrentPage2(event.selected);
    };

    useEffect(() => {
        getUserDeposits(adminInfo.token, params.userId)
        getUsersWidgetTransactions(adminInfo.token, params.userId)
    }, [])


    return (
        <>
            <div className='main-content'>
                <Header />

                <div className='padding'>
                    <div className='container-fluid'>
                        <div className='row gx-3'>
                            <div className='col-lg-12 col-12'>
                                <div className='card card1 h-auto'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-500 text-white">User Deposit</h5>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className='table table1 mb-0' style={{ "minWidth": "650px" }}>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{ "width": "50px" }}>#</th>
                                                        <th scope="col">Payment Type</th>
                                                        <th scope="col">Currency</th>
                                                        <th scope="col">Price</th>
                                                        <th scope="col">Date Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        currentItems && currentItems.length > 0 ? currentItems.map((items, ind) => {
                                                            const date = new Date(items.createdAt).toLocaleDateString()
                                                            const time = new Date(items.createdAt).toLocaleTimeString()
                                                            return <tr key={ind}>
                                                                <td>{currentPage * itemsPerPage + ind + 1 }</td>
                                                                <td>{items.paymentType}</td>
                                                                <td>{items.currency}</td>
                                                                <td>{items.price}</td>
                                                                <td>{time} <br /> {date}</td>
                                                            </tr>
                                                        })
                                                        : 
                                                        <tr>
                                                            <td colSpan={10}><p className='text-center mt-4'>No Records</p></td>
                                                        </tr>
                                                    }
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className=''>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={1}
                                        pageCount={pageCount}
                                        previousLabel="<"
                                        renderOnZeroPageCount={null}
                                        containerClassName={'pagination'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link'}
                                        breakClassName={'page-item'}
                                        breakLinkClassName={'page-link'}
                                        activeClassName={'active'}
                                        className={'pagination pagination1 mx-auto justify-content-center mt-4 mb-0'}
                                    />
                                </div>
                            </div>

                            <div className='col-lg-12 col-12 mt-4'>
                                <div className='card card1 h-auto'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-500 text-white">Users Widget Transactions</h5>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className='table table1 mb-0' style={{ "minWidth": "650px" }}>
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{ "width": "50px" }}>#</th>
                                                        <th scope="col">Payment Type</th>
                                                        <th scope="col">Currency</th>
                                                        <th scope="col">Price</th>
                                                        <th scope="col">Date Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        currentItems2 && currentItems2.length > 0 ? currentItems2.map((items, ind) => {
                                                            const date = new Date(items.createdAt).toLocaleDateString()
                                                            const time = new Date(items.createdAt).toLocaleTimeString()
                                                            return <tr key={ind}>
                                                                <td>{currentPage2 * itemsPerPage + ind + 1 }</td>
                                                                <td>{items.paymentMethod}</td>
                                                                <td>{items.currency}</td>
                                                                <td>
                                                                    {items.currency === "BTC" ? items.priceBTC 
                                                                    : items.currency === "USDT" ? items.priceUSDT 
                                                                    : items.priceTHB}
                                                                </td>
                                                                <td>{time} <br /> {date}</td>
                                                            </tr>
                                                        })
                                                        : 
                                                        <tr>
                                                            <td colSpan={10}><p className='text-center mt-4'>No Records</p></td>
                                                        </tr>
                                                    }
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className=''>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">"
                                        onPageChange={handlePageClick2}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={1}
                                        pageCount={pageCount2}
                                        previousLabel="<"
                                        renderOnZeroPageCount={null}
                                        containerClassName={'pagination'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link'}
                                        breakClassName={'page-item'}
                                        breakLinkClassName={'page-link'}
                                        activeClassName={'active'}
                                        className={'pagination pagination1 mx-auto justify-content-center mt-4 mb-0'}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default UserDeposit
