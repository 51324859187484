import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import ReactPaginate from 'react-paginate'
import { FaRegTrashAlt } from 'react-icons/fa'
import { useAllContext } from '../context/AllContext'
import axios from 'axios'
import { toast } from 'react-toastify'

const Exchange = () => {

    const adminInfo = JSON.parse(localStorage.getItem('adminInfo'))

    const { getExchangeList, exchangeList } = useAllContext()

    const [inputValue, setInputValue] = useState({
        advertiser: "",
        price: "",
        currencyFrom: "",
        currencyTo: "",
        paymentMethod: ""
    });

    const [errors, setErrors] = useState({});

    const inputHandler = (e) => {
        const {name, value} = e.target;
        setInputValue({
            ...inputValue,
            [name]: value
        })
    }


    const validate = () => {
        let errors = {};

        if (!inputValue.advertiser) {
            errors.advertiser = 'Advertiser is required';
        }

        if (!inputValue.price) {
            errors.price = 'Price is required';
        }

        if (!inputValue.currencyFrom) {
            errors.currencyFrom = 'Please select Currency From';
        }

        if (!inputValue.currencyTo) {
            errors.currencyTo = 'Please select Currency To';
        }

        if (!inputValue.paymentMethod) {
            errors.paymentMethod = 'Please select Payment Method';
        }

        return errors;
    };

    const formSubmit = async (e) => {
        e.preventDefault()

        const formErrors = validate();
        setErrors(formErrors);

        const details = {
            advertiser: inputValue.advertiser,
            price: inputValue.price,
            currencyFrom: inputValue.currencyFrom,
            currencyTo: inputValue.currencyTo,
            paymentMethod: inputValue.paymentMethod
        }

        if (Object.keys(formErrors).length === 0) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/add-p2p-exchange`, details, {
                    headers: {
                        Authorization: `${adminInfo.token}`
                    }
                })
                const data = await response.data

                if(data.status === 0){
                    toast.error(data.message)
                }

                if(data.status === 1){
                    toast.success(data.message)
                    getExchangeList(adminInfo.token)
                    setInputValue({
                        advertiser: "",
                        price: "",
                        currencyFrom: "",
                        currencyTo: "",
                        paymentMethod: ""
                    })
                }

            } catch (error) {
                console.log('Error:', error.response.data.message);
                toast.error(error.response.data.message)
            }
        }
    }

    // pagination
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 8
    useEffect(() => {
        if (exchangeList) {
            // Calculate the total number of pages
            const endOffset = itemOffset + itemsPerPage;
            setCurrentItems(exchangeList.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(exchangeList.length / itemsPerPage));
        }

    }, [itemOffset, itemsPerPage, exchangeList]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % exchangeList.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected)
    };
    // end pagination

    const deleteAccount = async (id) => {
        const details = {
            id: `${id}`
        }
        try {
            const resp = await axios.post(`${process.env.REACT_APP_API_URL}/admin/delete-p2p-exchange`, details, {
                headers: {
                    Authorization: `${adminInfo.token}`
                }
            })
            const data = await resp.data

            if (data.status == 0) {
                toast.error(data.message)
            }

            if (data.status == 1) {
                toast.success(data.message)
                getExchangeList(adminInfo.token)
            }


        } catch (error) {
            console.log('Error:', error);
        }
    }

    useEffect(() => {
        getExchangeList(adminInfo.token)
    }, [])


    return (
        <>
            <div className='main-content'>
                <Header />

                <div className='padding'>
                    <div className='container-fluid'>
                        <div className='row gx-3'>
                            <div className='col-lg-12 col-12'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-500 text-white">Add P2P Exchange</h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className='col-xl-7 col-lg-8 col-md-10 col-12'>
                                                <form className="form form1" onSubmit={formSubmit}>
                                                    <div className='mb-3'>
                                                        <label className='form-label text-white'>Advertiser</label>
                                                        <input type="text" className="form-control" placeholder="" value={inputValue.advertiser} name="advertiser" onChange={inputHandler} />
                                                        {errors.advertiser && <p className="error-text">{errors.advertiser}</p> }
                                                    </div>

                                                    <div className='mb-3'>
                                                        <label className='form-label text-white'>Price</label>
                                                        <input type="text" className="form-control" placeholder="" value={inputValue.price} name="price" onChange={inputHandler} />
                                                        {errors.price && <p className="error-text">{errors.price}</p> }
                                                    </div>
                                                    <div className="row gx-3">
                                                        <div className="col-md-6 col-12">
                                                            <div className='mb-3'>
                                                                <label className='form-label text-white'>Currency From</label>
                                                                <select className="form-select" aria-label="Default select" value={inputValue.currencyFrom} name="currencyFrom" onChange={inputHandler}>
                                                                    <option selected disabled value="">Select Currency</option>
                                                                    <option value="AUD">
                                                                        AUD
                                                                    </option>

                                                                    <option value="CAD">
                                                                        CAD
                                                                    </option>

                                                                    <option value="EUR">
                                                                        EUR
                                                                    </option>

                                                                    <option value="GBP">
                                                                        GBP
                                                                    </option>

                                                                    <option value="INR">
                                                                        INR
                                                                    </option>

                                                                    <option value="JPY">
                                                                        JPY
                                                                    </option>

                                                                    <option value="MXN">
                                                                        MXN
                                                                    </option>

                                                                    <option value="PHP">
                                                                        PHP
                                                                    </option>

                                                                    <option value="USD">
                                                                        USD
                                                                    </option>

                                                                    <option value="THB">
                                                                        THB
                                                                    </option>

                                                                    <option value="BTC">
                                                                        BTC
                                                                    </option>

                                                                    <option value="USDT">
                                                                        USDT
                                                                    </option>
                                                                </select>
                                                                {errors.currencyFrom && <p className="error-text">{errors.currencyFrom}</p> }
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <div className='mb-3'>
                                                                <label className='form-label text-white'>Currency To</label>
                                                                <select className="form-select" aria-label="Default select" value={inputValue.currencyTo} name="currencyTo" onChange={inputHandler}>
                                                                    <option selected disabled value="">Select Currency</option>
                                                                    <option value="AUD">
                                                                        AUD
                                                                    </option>

                                                                    <option value="CAD">
                                                                        CAD
                                                                    </option>

                                                                    <option value="EUR">
                                                                        EUR
                                                                    </option>

                                                                    <option value="GBP">
                                                                        GBP
                                                                    </option>

                                                                    <option value="INR">
                                                                        INR
                                                                    </option>

                                                                    <option value="JPY">
                                                                        JPY
                                                                    </option>

                                                                    <option value="MXN">
                                                                        MXN
                                                                    </option>

                                                                    <option value="PHP">
                                                                        PHP
                                                                    </option>

                                                                    <option value="USD">
                                                                        USD
                                                                    </option>

                                                                    <option value="THB">
                                                                        THB
                                                                    </option>

                                                                    <option value="BTC">
                                                                        BTC
                                                                    </option>

                                                                    <option value="USDT">
                                                                        USDT
                                                                    </option>
                                                                </select>
                                                                {errors.currencyTo && <p className="error-text">{errors.currencyTo}</p> }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='mb-3'>
                                                        <label className='form-label text-white'>Payment Method</label>
                                                        <select className="form-select" aria-label="Default select" value={inputValue.paymentMethod} name="paymentMethod" onChange={inputHandler}>
                                                            <option selected disabled value="">Select Payment Method</option>
                                                            <option value="Crypto">Crypto</option>
                                                            <option value="Bank Transfer">Bank Transfer</option>
                                                            {/* <option value="QR Code">QR Code</option>
                                                            <option value="Credit Card">Credit Card</option> */}
                                                        </select>
                                                        {errors.paymentMethod && <p className="error-text">{errors.paymentMethod}</p> }
                                                    </div>

                                                    <div className='mt-4'>
                                                        <button type='submit' className='btn btn1'>Add</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-12 col-12 mt-4'>
                                <div className='card card1'>
                                    <div className="card-header">
                                        <h5 className="mb-0 fs-18 fw-500 text-white">P2P Exchange</h5>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className='table table1 mb-0' style={{ "minWidth": "650px" }}>
                                                <thead>
                                                <tr>
                                                    <th scope="col" style={{ "width": "50px" }}>#</th>
                                                    <th scope="col">Advertiser</th>
                                                    <th scope="col">Price</th>
                                                    <th scope="col">Currency From</th>
                                                    <th scope="col">Currency To</th>
                                                    <th scope="col">Payment Method</th>
                                                    <th scope="col" className='text-center'>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    currentItems && currentItems.length > 0 ? currentItems.map((items, ind) => {
                                                            const date = new Date(items.createdAt).toLocaleDateString()
                                                            return <tr key={ind}>
                                                                <td>{currentPage * itemsPerPage + ind + 1 }</td>
                                                                <td>{items.advertiser}</td>
                                                                <td>{items.price}</td>
                                                                <td>{items.currencyFrom}</td>
                                                                <td>{items.currencyTo}</td>
                                                                <td>{items.paymentMethod}</td>
                                                                <td className="text-center"><button type="submit" onClick={() => {deleteAccount(items.id)}} className="btn btn1 py-1 px-2 bg-danger border-danger text-white" style={{"minWidth": "auto"}}><FaRegTrashAlt /></button></td>
                                                            </tr>
                                                        })
                                                        :
                                                        <tr>
                                                            <td colSpan={10}><p className='text-center mt-4'>No Records</p></td>
                                                        </tr>
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='col-12'>
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={1}
                                    pageCount={pageCount}
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                    containerClassName={'pagination'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                    breakClassName={'page-item'}
                                    breakLinkClassName={'page-link'}
                                    activeClassName={'active'}
                                    className={'pagination pagination1 mx-auto justify-content-center mt-4 mb-0'}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default Exchange
