import React from 'react'

const Footer = () => {
    return (
        <>
            <div className="footer-section padding">
                <div className="container-fluid">
                    <p className="text-center mb-0">Copyright © 2024 OnePay Global.</p>
                </div>
            </div>
        </>
    )
}

export default Footer
