
const SigninValidation = (value) => {
    const errors = {}  
    const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (value.email === "") {
        errors.email = "Email is required"
    } 
    else if (emailReg.test(value.email) === false) {
        errors.email = "Invalid Email";
    } else {
        errors.email = "";
    }


    if (value.password === "") {
        errors.password = "Password is required"
    } else {
        errors.password = "";
    }

    return errors;
}

export default SigninValidation

